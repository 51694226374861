<template id="categorie-view">
  <div>
    <form
      ref="categories"
      @submit.stop.prevent="validationForm"
    >
      <b-overlay
        :show="show"
        :opacity="1.00"
        :blur="'2px'"
        rounded="lg"
      >
        <b-row>
          <b-col style="align-self: center; text-align: end;">
            <b-button
              variant="success"
              @click="handleOk"
            >
              {{ $t('dataGeneric.save') }}
            </b-button>
          </b-col>
        </b-row>

        <b-form-group
          :label="$t('firstName') + '*'"
          label-for="name-input"
          :invalid-feedback="$t('required')"
          :state="nameState"
        >
          <b-form-input
            id="name-input"
            v-model="name"
            :placeholder="$t('firstName')"
            :state="nameState"
            maxlength="150"
            required
          />
        </b-form-group>
        <b-form-group
          :label="$t('dataGeneric.description')"
          label-for="description"
        >
          <b-form-textarea
            id="description"
            v-model="description"
            class="mb-1"
            :placeholder="$t('dataGeneric.description')"
            rows="2"
            max-rows="4"
          />
        </b-form-group>
      </b-overlay>
    </form>
  </div>
</template>
<script>
import {
  BFormTextarea,
  BFormInput,
  BFormGroup,
  BButton,
  BCol,
  BRow,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError, showToast } from '@/store/functions'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BCol,
    BRow,
  },
  directives: {
    Ripple,
  },
  props: {
    language: {
      type: String,
      default: null,
    },
    id: { default: null },
    base: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      name: '',
      description: '',
      userData: getUserData(),
      nameState: null,
    }
  },
  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) {
    }
    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    if (this.id) this.getCategories()
  },
  methods: {
    getCategories() {
      const { headers } = this
      axios
        .post('', {
          query: `
          {
            categoryCharacter(
              id:"${this.id}"
              client: "${this.userData.profile.client.id}"
              orderBy: "order"
            ) {
              edges {
                node {
                  id
                  name(lang:"${this.language}")
                  description(lang:"${this.language}")
                }
              }
            }
          }
        `,
        }, { headers })
        .then(res => {
          messageError(res, this)
          this.name = res.data.data.categoryCharacter.edges[0].node.name
          this.description = res.data.data.categoryCharacter.edges[0].node.description
          this.show = false
        })
        .catch(() => {
          this.show = false
        })
    },
    handleOk() {
      if (this.id !== null) { this.editCategoryCharacter() } else this.createCharacterCategory()
    },
    createCharacterCategory() {
      if (!this.checkFormValidity()) {
        return
      }
      const { headers } = this
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const query = `
              mutation{
                createCharacterCategory(input:{name:"${this.name}",description:"${this.description}",client:"${this.userData.profile.client.id}"}){
                  categoryCharacter {
                    id
                    name
                  }
                }
              }
            `
      data.append('query', query)
      axios
        .post('', data, { headers }, config)
        .then(res => {
          messageError(res, this)
          showToast(this.$t('success'), 1, this)
          this.$nextTick(() => {
            this.$bvModal.hide('categories-character-view')
          })
          this.$emit('refresh')
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
          this.show = false
        })
    },

    editCategoryCharacter() {
      const { headers } = this

      if (!this.checkFormValidity()) {
        return
      }
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      let objectVariables = []
      objectVariables = [
        { title: `name${this.language}`, value: this.name, type: 'String' },
        { title: `description${this.language}`, value: this.description, type: 'String' },
      ]

      const variables = {}
      let mutation = 'mutation('
      let query = `{updateCharacterCategory(id:"${this.id}",input:{\n
          `
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'
      query += `}){
                  categoryCharacter {
                     id
                   }
                  }
              }`
      query = mutation + query
      data.append('query', query)
      data.append('variables', JSON.stringify(variables))

      axios
        .post('', data, { headers }, config)
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.updateData'), 1, this)
          this.$emit('refresh')
        })
        .catch(() => {
          showToast(this.$t('code.updateDataError'), 2, this)
          this.show = false
        })
    },
    checkFormValidity() {
      const valid = this.$refs.categories.checkValidity()
      this.nameState = valid
      return valid
    },

  },
}
</script>

<style lang="scss" scoped>
#badge-view .edit:hover {
  color: rgb(0, 83, 7);
  cursor: pointer;
}

#badge-view .trash:hover {
  color: rgb(109, 0, 0);
  cursor: pointer;
}

#badge-view .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#badge-view .card-body h4 {
  font-size: 1.286rem !important;
}

#badge-view .col-form-label {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

#badge-view .fly-image-input {
  max-width: 100%;
}

#badge-view .base-color-input {
  display: block;
  border-radius: 15px;
  border: solid 1px;
  border-color: white;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#badge-view .base-card-input {
  display: block;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

#badge-view .div {
  display: inline-block;
}

#badge-view .inputColor {
  visibility: hidden;
}

#badge-view .placeholder_photo {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

#badge-view .placeholder_photo:hover {
  background: #e0e0e0;
}

#badge-view .file-input {
  display: none;
}

#badge-view .cBox {
  align-items: center;
}

#badge-view .custom-control-label {
  font-size: 15px;
}

#badge-view .scroll {
  overflow: hidden;
  flex-wrap: nowrap !important;

}

#badge-view .list {
  overflow: hidden;
  overflow-y: scroll;
  height: 380px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: none;
}

#badge-view .contain {
  object-fit: contain;
  cursor: pointer;
}
</style>
